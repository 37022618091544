<template>
    <div class="ml-10 mr-10">

        <TitleBar :loading="loading" :text="this.$t('company.settings.details')"></TitleBar>

        <v-alert
            v-if="inbound"
            border="top"
            class="mb-10"
            colored-border
            elevation="2"
            type="info"
        >
            <span v-html="$t('company.inbound', {inbound})"></span>
        </v-alert>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="name"
                        :disabled="loading"
                        :error-messages="nameErrors"
                        :label="$t('company.name')"
                        outlined
                        @blur="$v.name.$touch()"
                        @input="$v.name.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="address_firstline"
                        :disabled="loading"
                        :error-messages="addressFirstLineErrors"
                        :label="$t('address.firstline')"
                        outlined
                        @blur="$v.address_firstline.$touch()"
                        @input="$v.address_firstline.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="address_secondline"
                        :disabled="loading"
                        :label="$t('address.secondline')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="address_postcode"
                        :disabled="loading"
                        :error-messages="addressPostcodeErrors"
                        :label="$t('address.postcode')"
                        outlined
                        @blur="$v.address_postcode.$touch()"
                        @input="$v.address_postcode.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="address_city"
                        :disabled="loading"
                        :error-messages="addressCityErrors"
                        :label="$t('address.city')"
                        outlined
                        @blur="$v.address_city.$touch()"
                        @input="$v.address_city.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="address_country"
                        :disabled="loading"
                        :error-messages="addressCountryErrors"
                        :items="countries"
                        :label="$t('address.country')"
                        outlined
                        @blur="$v.address_country.$touch()"
                        @input="$v.address_country.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="registration_number"
                        :disabled="loading"
                        :error-messages="regErrors"
                        :label="$t('company.registration_number')"
                        outlined
                        @blur="$v.registration_number.$touch()"
                        @input="$v.registration_number.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="tax_number"
                        :disabled="loading"
                        :label="$t('company.tax_number')"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('company.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-divider class="mb-5"></v-divider>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="email"
                        :disabled="loading"
                        :error-messages="emailErrors"
                        :label="$t('company.email')"
                        outlined
                        type="email"
                        @blur="$v.email.$touch()"
                        @input="$v.email.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="phone"
                        :disabled="loading"
                        :label="$t('company.phone')"
                        outlined
                        type="tel"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="website"
                        :disabled="loading"
                        :error-messages="urlErrors"
                        :label="$t('company.website')"
                        outlined
                        type="url"
                        @blur="$v.website.$touch()"
                        @input="$v.website.$touch()"
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $t("company.update") }}
            </v-btn>
        </v-form>

    </div>
</template>

<script>
import Currencies from "@/helpers/Currencies";
import { validationMixin } from "vuelidate";
import { email, required, url } from "vuelidate/lib/validators";
import Countries from "@/helpers/Countries";
import Accounting from "@/helpers/Accounting";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.details"),
                to: "/company/settings/details",
                exact: true
            }
        ]);


        this.countries = Countries.get(this.$i18n.locale);

        this.loading = true;
        Accounting.get("/companies/" + this.$store.state.company.uuid)
            .then(response => {
                const data = response.data;
                this.currency = data.currency;
                this.email = data.email;
                this.inbound = data.inbound;
                this.logo = data.logo;
                this.name = data.name;
                this.phone = data.phone;
                this.prefix = data.prefix;
                this.registration_number = data.registration_number;
                this.tax_number = data.tax_number;
                this.website = data.website;
                this.address_city = data.address.city;
                this.address_country = data.address.country;
                this.address_firstline = data.address.firstline;
                this.address_postcode = data.address.postcode;
                this.address_secondline = data.address.secondline;
                this.loading = false;
            }).catch(error => {
            if (error.response) {
                let errors = [];
                for (const errorElement of error.response.data.errors) {
                    errors.push({
                        type: "error",
                        text: errorElement
                    });
                }
                this.$store.commit("SET_ALERTS", errors);
            } else if (error.request) {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: this.$t("api.no_response")
                }]);
            } else {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: error.message
                }]);
            }
            this.loading = false;
        });
    },
    data() {
        return {
            loading: false,
            currency: null,
            email: null,
            inbound: null,
            logo: null,
            name: null,
            phone: null,
            prefix: null,
            registration_number: null,
            tax_number: null,
            website: null,
            address_city: null,
            address_country: null,
            address_firstline: null,
            address_secondline: null,
            address_postcode: null,
            countries: [],
            currencies: Currencies.get()
        };
    },
    methods: {
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                this.$store.commit("SET_ALERTS", []);

                Accounting.post("/companies/" + this.$store.state.company.uuid, {
                    address_city: this.address_city,
                    address_country: this.address_country,
                    address_firstline: this.address_firstline,
                    address_postcode: this.address_postcode,
                    address_secondline: this.address_secondline,
                    currency: this.currency,
                    email: this.email,
                    name: this.name,
                    phone: this.phone,
                    tax_number: this.tax_number,
                    website: this.website,
                    registration_number: this.registration_number
                }).then(() => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("company.updated")
                    }]);
                    this.loading = false;
                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        email: {email},
        website: {url},
        name: {required},
        address_firstline: {required},
        address_postcode: {required},
        address_city: {required},
        address_country: {required},
        currency: {required},
        registration_number: {required}

    },
    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push(this.$t("company.email_valid"));
            return errors;
        },
        urlErrors() {
            const errors = [];
            if (!this.$v.website.$dirty) return errors;
            !this.$v.website.url && errors.push(this.$t("company.url_valid"));
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push(this.$t("company.name_required"));
            return errors;
        },
        addressFirstLineErrors() {
            const errors = [];
            if (!this.$v.address_firstline.$dirty) return errors;
            !this.$v.address_firstline.required && errors.push(this.$t("company.firstline_required"));
            return errors;
        },
        addressPostcodeErrors() {
            const errors = [];
            if (!this.$v.address_postcode.$dirty) return errors;
            !this.$v.address_postcode.required && errors.push(this.$t("company.postcode_required"));
            return errors;
        },
        addressCityErrors() {
            const errors = [];
            if (!this.$v.address_city.$dirty) return errors;
            !this.$v.address_city.required && errors.push(this.$t("company.city_required"));
            return errors;
        },
        addressCountryErrors() {
            const errors = [];
            if (!this.$v.address_country.$dirty) return errors;
            !this.$v.address_country.required && errors.push(this.$t("company.country_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("company.currency_required"));
            return errors;
        },
        regErrors() {
            const errors = [];
            if (!this.$v.registration_number.$dirty) return errors;
            !this.$v.registration_number.required && errors.push(this.$t("company.reg_required"));
            return errors;
        }
    }
};
</script>


<style scoped>

</style>